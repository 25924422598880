@import '/src/styles/variables';
@import '/src/styles/mixins/index';

.subheader_wrapper {
  max-width: 790px;
  margin: 0 auto;
  padding: 3px 10px 10px 40px;

  @include b(765) {
    padding: 3px 0 10px;
  }
}

.totals {
  @include flex(row, space-between, center);
  margin-bottom: 27px;

  .total_item {
    @include flex(row, center, center);

    .total {
      margin-left: 13px;
    }
    .label {
      margin-top: 5px;
    }
  }

  @include b(635) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0px;
  }
}

.fund {
  @include flex(row, space-between, center);

  .powered {
    @include flex(row, center, center);
    svg {
      margin-left: 13px;
    }
  }
  .btn_fund {
    max-width: 309px;
  }

  @include b(735) {
    margin-top: 20px;
    flex-direction: column;

    .btn_fund {
      max-width: none;
      margin-top: 10px;
    }
  }
}
