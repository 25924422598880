@import '../../../../styles/index';

.success_modal {
  width: 100%;
  max-width: 650px;
  border-radius: $borderRadius;
  padding: 50px;

  .content {
    @include flex(column, center, center);
  }

  .icon {
    margin-bottom: 10px;
  }
  .title,
  .description_top {
    margin-bottom: 20px;
  }
  .description_bottom {
    margin-bottom: 50px;
  }

  .btn_wrapper {
    width: 100%;
    @include flex(row, space-between, center);
    .btn {
      width: calc((100% - 10px) / 2);
      padding: 17.5px;
      * {
        margin-left: 10px;
      }
    }
  }
}
