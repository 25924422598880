@import '/src/styles/variables';
@import '/src/styles/mixins';

.referrals_wrapper {
  padding-bottom: 50px;
}

.section {
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
  @include container(580);
  padding: 40px;
  background: #fff;
  border-radius: $borderRadius;
  @include b(600) {
    padding: 20px;
  }
}

.inputWrapper {
  margin-bottom: 8px;
}
.input {
  text-transform: uppercase;
}
.inputLabelEnd {
  position: absolute;
  right: 5px;
  top: 37px;
  text-transform: initial;
}
.codeButton {
  display: inline-block;
}
