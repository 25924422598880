* {
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #1969ff;
    border: 3px solid #1969ff;
  }
  scrollbar-width: thin;
  scrollbar-color: #1969ff #161313;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $textMain;

  span,
  div {
    font-feature-settings: 'liga' off;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

.card {
  max-width: 610px;
  margin: 50px auto 20px;
  background: #fff;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
  border-radius: $borderRadius;
  padding: 40px;
  @include b(500) {
    padding: 20px;
  }

  &_header {
    @include flex(row);
    flex-wrap: nowrap;
    margin-bottom: 10px;
    padding-bottom: 10px;

    @include b(600) {
      width: 100%;
      overflow: auto;
    }
  }
}
