@import '/src/styles/variables';
@import '/src/styles/mixins';

.expander {
  position: relative;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
  border-radius: $borderRadius;

  // colors
  &.white {
    padding: 30px;
    background: #fff;
    @include b(500) {
      padding: 25px;
    }
  }

  &.blue, &.green {
    padding: 20px 30px;
    box-shadow: none;
    @include b(500) {
      padding: 15px 25px;
    }
  }
  &.blue {
    background: $blueSemi10;
  }
  &.green {
    background: $greenSemi10;
  }

  // variants
  &.small {
    padding: 20px;
    background: #f6f9ff;
    box-shadow: none;
    @include b(500) {
      padding: 15px;
      padding-right: 25px;
    }
  }
  &.small .header {
    text-transform: uppercase;
  }
  &.small .button {
    width: 20px;
    height: 20px;
    top: 22px;
    right: 22px;
    background: transparent;
    padding: 0;
    @include b(500) {
      top: 18px;
      right: 10px;
    }

    &:hover {
      .buttonArrow path {
        stroke: $darkBlue;
      }
    }
  }
  &.small .buttonArrow {
    margin-left: 0;
  }
}

.button {
  border-radius: $borderRadius;
  padding: 8px 15px;

  .buttonTitle {
    @include b(500) {
      display: none;
    }
  }

  &.white {
    background: $bgActiveMenuLink;
  }

  &.blue {
    background: #fff;
    &:hover {
      background: $blue;
    }
  }
  &.green {
    background: #fff;
    &:hover {
      background: $green;
    }
  }

  &:hover {
    .buttonArrow path {
      stroke: #fff;
    }
    .buttonTitle {
      color: #fff;
    }
  }

  position: absolute;
  top: 10px;
  right: 10px;
}

.buttonTitle {
  transition: 0.4s ease;
  color: $darkBlue;
}

.buttonArrow {
  margin-left: 10px;
  @include b(500) {
    margin-left: 0;
  }
  transition: 0.4s ease;
  path: {
    transition: 0.4s ease;
  }
  &.buttonArrowActive {
    transform: rotate(0.5turn);
  }
}

.content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), margin 0.25s cubic-bezier(0, 1, 0, 1);
}

.contentActive {
  transition: max-height 1s ease-in-out, margin 0.5s ease-in-out;
  max-height: 1000px;
  margin-top: 20px;
}
