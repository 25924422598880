@import '/src/styles/variables';
@import '/src/styles/mixins';

.infoItem {
  --current-color: #60c9c8;
  background: #ffffff;
  box-shadow: 0px 6.48518px 60.7582px rgba(0, 0, 0, 0.05);
  border-radius: $borderRadius;
  width: 100%;
  border: 1px solid var(--current-color);
  padding: 30px;
  transition: 0.4s ease;

  @include b(600) {
    padding: 20px;
  }

  @include flex(row, flex-start, center);

  &.green {
    --current-color: #60c9c8;
  }
  &.blue {
    --current-color: #1969ff;
  }
  &.purple {
    --current-color: #d19ef8;
  }
  &.orange {
    --current-color: #f1aca7;
  }

  &:hover {
    background: var(--current-color);
    .copyIcon path {
      fill: #fff;
    }
  }
}

.left {
  margin-right: 10px;
  height: 40px;
  svg {
    width: 40px;
    height: 40px;
  }
}

.copyIcon {
  transition: 0.4s ease;
  align-self: flex-end;
  margin-left: 8px;
}
