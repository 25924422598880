@import '/src/styles/variables';
@import '/src/styles/mixins';

.section {
  padding: 40px;
  margin-top: 20px;
  background: rgba($blue, 0.1);
  border-radius: $borderRadius;
}

.title {
  margin-bottom: 24px;
}

.statistic {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 35px;

  @include b(800) {
    padding: 20px;
  }

  @include b(600) {
    grid-template-columns: 1fr;
  }
}

.item {
  @include flex(row, flex-start);
  transition: 0.4s ease;
  &:hover {
    transform: translateY(-5px);
  }

  &Icon {
    margin-right: 15px;
  }

  &Title {
    margin-bottom: 5px;
  }
}
