@import '/src/styles/variables';
@import '/src/styles/mixins';

.infoPopover {
  min-width: 32px;
  width: 32px;
  height: 30px;
  @include flex(row, center, center);
  border-radius: 20px 20px 0 0;
  position: relative;
  transition: 0.4s ease;

  &:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    color: $darkBlue;
    background: #fff;
    .infoPopoverContent {
      opacity: 1;
      visibility: visible;
    }
  }
}

.infoPopoverContent {
  position: absolute;
  visibility: hidden;
  top: 30px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  background: #fff;
  opacity: 0;
  transition: 0.4s ease;
  padding: 20px 25px;
  border-radius: $borderRadius;
  font-size: 12px;
  font-weight: 500;
  color: $darkBlue;
  width: max-content;
  z-index: 1000;
}

.left {
  left: -10px;
}
.right {
  right: -10px;
}
.center {
  right: 50%;
  transform: translateX(50%);
}
