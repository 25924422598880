@import '/src/styles/variables';
@import '/src/styles/mixins';

.labelSection {
  padding: 25px 30px;
  border-radius: $borderRadius;
  @include flex(row, space-between, center);
  flex-wrap: nowrap;
  @include b(500) {
    padding: 15px 10px;
  }
}

.left {
  @include flex(row, flex-start, center);
  flex-wrap: nowrap;
}

.title {
  text-transform: uppercase;
  letter-spacing: 0.15em;
  @include b(400) {
    font-size: 15px;
  }
}

.icon {
  margin-right: 15px;
  min-width: 50px;
  width: 50px;
  height: 50px;
  svg {
    width: 100%;
    height: 100%;
  }

  @include b(500) {
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }
}
