@import '/src/styles/variables';
@import '/src/styles/mixins';

.xbf_wrapper {
  @include container(770);
  padding: 0;
  padding-bottom: 50px;
}

.link {
  & + & {
    margin-left: 32px;
  }
}

.linkTitle {
  text-transform: capitalize;
  transition: 0.4s ease;
  &Active,
  &:hover {
    font-weight: 700;
  }

  @include flex(column);

  // Не знаю, как сделать лучше, чтобы при наведении из-за font-weigth не дергалось все.
  &.stake {
    &::before {
      content: 'stake';
      font-weight: 700;
      height: 0;
      overflow: hidden;
    }
  }
  &.lockup {
    &::before {
      content: 'lockup';
      font-weight: 700;
      height: 0;
      overflow: hidden;
    }
  }
  &.liquidity {
    &::before {
      content: 'liquidity';
      font-weight: 700;
      height: 0;
      overflow: hidden;
    }
  }
  &.rewards {
    &::before {
      content: 'rewards';
      font-weight: 700;
      height: 0;
      overflow: hidden;
    }
  }
  &.boost {
    &::before {
      content: 'boost';
      font-weight: 700;
      height: 0;
      overflow: hidden;
    }
  }
}
