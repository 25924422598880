@import '/src/styles/variables';

.h1 {
  font-size: 34px;
  line-height: 41px;
  font-weight: 700;
}
.h2 {
  font-size: 29px;
  line-height: 35px;
  font-weight: 600;
}
.h3 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 600;
}
.h4 {
  font-size: 21px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 0.1em;
}
.h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}
.h6 {
  font-size: 17px;
  line-height: 26px;
  font-weight: 700;
}
.body1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
.body2 {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}
.sub1 {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}
.sub2 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}
.label1 {
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
}
.label2 {
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

.normal {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.semiBold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}

.default {
  color: $textMain;
}
.white {
  color: $textWhite;
}
.blue {
  color: $blue;
}
.pink {
  color: $purple;
}
.purple {
  color: $purple;
}
.gray {
  color: $gray;
}
.accent {
  color: $textAccent;
}
.label1 {
  color: $textLabel1;
}
.label2 {
  color: $textLabel2;
}
.details {
  color: $textDetails;
}
.green {
  color: $green;
}
