@import '/src/styles/variables';
@import '/src/styles/mixins';

.stakeCard {
}
.headerStatistic {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  gap: 10px;
}

.headerToken {
  @include flex(row, flex-start, center);

  & > svg {
    height: 30px;
    margin-right: 10px;
  }
}

.input {
  margin: 30px 0 5px;
}
.inputLabelBalance {
  span {
    font-weight: 700;
  }
}

.additionalInfo {
  margin-top: 20px;
}

.infoItem {
  @include flex;
  flex-wrap: nowrap;
  @include b(600) {
    flex-direction: column;
  }
  &Title {
    flex-grow: 1;
    @include b(600) {
      margin-bottom: 2px;
    }
  }
  &Value {
    color: $darkBlue;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & + & {
    margin-top: 10px;
  }
}

.infoItemRight {
  @include flex;
  flex-wrap: nowrap;
  max-width: 50%;
  margin-left: 10px;
  @include b(600) {
    margin-left: 0;
    max-width: 100%;
  }
}

.popover {
  @include b(600) {
    right: 80px;
  }
}

.multipleInputs {
  display: flex;
  gap: 10px;
}

.crvInputLabelEnd {
  padding-right: 50px;
  text-transform: lowercase;
}
