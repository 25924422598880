@import '/src/styles/variables';
@import '/src/styles/mixins';

.liquidity_wrapper {
}

.boost_stats {
  border-radius: $borderRadius;
  background: $blueSemi10;
  padding: 30px;
  margin: 5px 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  gap: 20px;
  @include b(500) {
    grid-template-columns: 1fr;
  }
}
.stats_item {
}
.subtitle,
.progress {
  margin-bottom: 20px;
}

.label {
  margin-bottom: 5px;
}

.tip {
  margin: 65px 0 8px;
}
.balance {
  color: $textAccent;
  font-weight: 400;
  span {
    font-weight: 700;
  }
}

.input {
  margin-top: 20px;
}
.range {
  margin-bottom: 60px;
}
.btn {
  margin-top: 8px;
}

.labelTitle {
  font-size: 19px;
  @include b(600) {
    font-size: 15px;
  }
}

.secondLabel {
  margin-bottom: 20px;
}
