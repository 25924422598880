@import '/src/styles/variables';
@import '/src/styles/mixins';

.liquidity_wrapper {
}

.locked_stats {
  border-radius: $borderRadius;
  background: $bgLockup;
  padding: 30px;
  margin: 5px 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  @include b(500) {
    grid-template-columns: 1fr;
  }
}
.stats_item {
}
.label {
  margin-bottom: 5px;
}

.input {
  margin-top: 65px;
}
.btn {
  margin-top: 8px;
}

.balance {
  color: $purple;
  font-weight: 400;
  span {
    font-weight: 700;
  }
}

.unlockLabel {
  @include flex(row, center, center);
  svg {
    width: 15px;
    height: 15px;
  }
}
.unlockLabelPopup {
  text-transform: none;
}
