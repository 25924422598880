@import '/src/styles/variables';
@import '/src/styles/mixins';

.header_wrapper {
  @include container(1073);

  padding-top: 30px;
  margin-bottom: 40px;
}

.header_inner {
  @include flex(row, space-between, center);
  flex-wrap: nowrap;
}

.menu_wrapper {
  @include flex(row, space-between, center);
  gap: 10px;
  flex-wrap: nowrap;

  // tabs adaptive
  margin: 0 20px;
  width: fit-content;
  white-space: nowrap;
  overflow-x: auto;
  @include b(920) {
    width: 100%;
    margin: 20px 0 0;
    padding-bottom: 10px;
  }

  .menu_link {
    border-radius: 10px;
    padding: 8px 16px;
    font-weight: 600;
    color: $textMain;
    transition: 0.4s ease;

    &.active,
    &:hover {
      background: $bgActiveMenuLink;
    }
  }
}

.button {
  width: 140px;
}

.userAddress {
  background: #ffffff;
  border-radius: 10px;
  padding: 8px 10px;
  color: $darkBlue;
  display: flex;
  align-items: center;
}

.address {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}
