@import '/src/styles/variables';
@import '/src/styles/mixins';

.additionalInformation {
  max-width: 610px;
  margin: 0 auto;
}

.icon {
  margin-right: 25px;
}
.subtitle {
  color: $darkBlue;
  a {
    color: $blue;
  }
}
.content {
  @include flex(row, normal, center);
}

.info {
  background: rgba($blue, 0.1);
  border-radius: $borderRadius;
  padding: 30px;
  margin-bottom: 20px;
  @include b(600) {
    padding: 0;
    background: transparent;
  }
}

.subinfo {
  border: 1px solid $lightBlue;
  padding: 30px;
  border-radius: $borderRadius;

  @include flex(row, space-between);
  @include b(600) {
    flex-direction: column;
    padding: 25px;
  }
}

.row {
  @include flex(row, space-between);
  @include b(600) {
    flex-direction: column;
  }
  & + & {
    margin-top: 15px;
  }
}

.headerContent {
  margin-top: 40px;
  @include b(500) {
    margin-top: 20px;
  }
}
