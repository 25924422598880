@import '/src/styles/variables';
@import '/src/styles/mixins';

.custody_wrapper {
  @include container(610);
}

.coming_soon_wrapper {
  background: $bgMain;
  box-shadow: 0 6.48518px 60.7582px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 40px 30px 119px 30px;
  @include flex(row, flex-start, center);
  position: relative;
}
.text_wrapper {
  margin-left: 5px;
}
.title {
  @include b(mobile) {
    font-size: 16px;
    line-height: 24px;
  }
}
.coming_soon {
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  text-transform: uppercase;
  color: $blue;
  @include b(mobile) {
    font-size: 32px;
    line-height: 40px;
  }
}
.xb3_logo {
  position: absolute;
  right: 40px;
  bottom: 40px;
}
