@import '/src/styles/variables';
@import '/src/styles/mixins';

.title {
  width: 100%;
  margin-bottom: 8px;
}
.subtitle {
  margin-bottom: 14px;
  opacity: 0.5;
}
:global(.rc-slider-mark) {
  width: calc(100% - 4px);
  left: 2px;
}

.slider_header {
  @include flex(row, space-between, center);
}
