@import '/src/styles/variables';
@import '/src/styles/mixins';

.main_wrapper {
  position: relative;
  overflow: hidden;
  z-index: 0;
  min-height: 100vh;
  background-color: $bgMain;
  background-image: url('../../assets/img/background.png');
  background-size: cover;
  @include flex(column, flex-start, center);
}

.page_wrapper {
  @include container;
}
