@import '/src/styles/variables';
@import '/src/styles/mixins/index';

.claim_wrapper {
}

.claims {
  background: rgba(50, 51, 90, 0.1);
  border-radius: 10px;
  padding: 22px 30px;
  margin-top: 20px;
}

@include b(700) {
  .wrapper {
    min-width: 500px;
  }

  .scrollableContainer {
    overflow-x: auto;
    margin-bottom: 20px;
  }
}

.claim_table_header {
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  row-gap: 20px;
  padding-top: 12px;
  margin-bottom: 8px;
}
.claim_table_content {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  row-gap: 20px;
  align-items: center;
  :not(:last-child) {
    @include flex(row, flex-start, center);
  }
}
.logo_title_wrapper {
  :first-child {
    margin-right: 8px;
  }
}

.total_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 38px;
}

.claimButton {
  height: 30px;
  border-radius: 5px;
}

.moreTitle {
  margin-bottom: 27px;
}
