@import '/src/styles/variables';
@import '/src/styles/mixins';

.hive_wrapper {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 52px;
}

.link {
  & + & {
    margin-left: 32px;
  }
}

.linkTitle {
  @include flex(column);
  text-transform: capitalize;
  transition: 0.4s ease;

  &Active,
  &:hover {
    font-weight: 700;
  }

  &.stake {
    &::before {
      content: 'stake';
      font-weight: 700;
      height: 0;
      overflow: hidden;
    }
  }
  &.lockup {
    &::before {
      content: 'claim';
      font-weight: 700;
      height: 0;
      overflow: hidden;
    }
  }
}
