@import '/src/styles/variables';
@import '/src/styles/mixins';

.select_wrapper {
  background: #fff;
  border: 1px solid $lightBlue;
  border-radius: 20px;
  padding: 8px 18px 8px 20px;
  position: relative;
  max-height: 40px;
  min-width: 190px;
  transition: box-shadow 0.4s ease;
  &:hover {
    box-shadow: 0px 8px 60px rgba(0, 0, 0, 0.1);
  }
  &.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }
}
.select {
  @include flex(row, flex-start, center);
  cursor: pointer;
  .sort_by {
    margin: 0 auto 0 5px;
  }
}
.options {
  background: #fff;
  position: absolute;
  z-index: 1000;
  top: 40px;
  left: -1px;
  right: 0;
  background: $bgMain;
  border: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0 20px 0;
  width: calc(100% + 2px);
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  max-height: 0;
  overflow: hidden;
  &.active {
    border: 1px solid $lightBlue;
    border-top: none;
    max-height: 1200px;
    transition: max-height 1s ease-in-out;
  }
}
.option {
  padding: 10px 0 10px;
  border-bottom: 0.5px solid $lightBlue;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.4s ease;
  &:hover {
    padding-left: 5px;
  }
  &:last-of-type {
    border: none;
  }
}

.icon {
  margin-left: 10px;
}
