@import '/src/styles/variables';
@import '/src/styles/mixins';

.termOfUseModal {
  width: 90%;
  max-width: 760px;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  padding-bottom: 0;
}

.content {
  padding: 40px;
  padding-top: 0;
  @include b(500) {
    padding: 20px;
  }
}

.warnIcon {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}

.title {
  max-width: 680px;
  text-align: center;
  margin-bottom: 20px;

  @include b(500) {
    font-size: 22px;
  }

  @include b(400) {
    font-size: 18px;
  }
}

.subtitle {
  margin-bottom: 20px;
  font-size: 13px;
  letter-spacing: 0.05em;
}

.list {
  background: #ecf2ff;
  border-radius: 10px;
  padding: 20px 20px 20px 40px;
  margin-bottom: 50px;
}

.listItem {
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #32335a;
  list-style-type: disc;
  & + & {
    margin-top: 15px;
  }
}
