@import '/src/styles/variables';
@import '/src/styles/mixins/index';

.select {
  margin-top: 10px;
}

.input {
  flex-grow: 1;
  margin-right: 10px;
  @include b(500) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.stakeCard {
  margin-top: 20px;
}
