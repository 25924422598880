@import '/src/styles/variables';
@import '/src/styles/mixins';

.subheader {
  max-width: 770px;
  margin: 0 auto;
}
.referral_wrapper {
  @include flex(row, flex-start, center);
  margin-bottom: 37px;

  .title {
    margin: 0 10px;
    max-width: 364px;
  }
  .referral_btn {
  }
}

.referral_title__wrapper {
  @include flex;
  align-items: center;
  flex-wrap: nowrap;
  @include b(650) {
    margin-bottom: 10px;
  }
}

.xbf_stats {
  @include flex(row, center, center);

  .docs_btn {
    max-width: 220px;
    margin-left: 74px;
  }

  @include b(650) {
    display: block;
    .docs_btn {
      max-width: 100%;
    }

    .xbf_stat {
      margin-bottom: 15px;
    }
  }
}
