@import '/src/styles/variables';
@import '/src/styles/mixins';

.mainInfo {
  @include container(770);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @include b(800) {
    grid-template-columns: 1fr;
    padding: 0;
  }

  margin-bottom: 20px;
}
