@import '/src/styles/variables';
@import '/src/styles/mixins';

.detailedInfoPopover {
}
.link {
  color: $blue;
}

.content {
  @include b(500) {
    right: -20px;
  }
}
