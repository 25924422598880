@import '/src/styles/variables';
@import '/src/styles/mixins';

.referralNetwork {
  @include container(580);
  margin-top: 20px;
}
.headerContent {
  @include flex(row, normal, center);
}
.headerIcon {
  margin-right: 15px;
}
.headerSubtitle {
  color: $darkBlue;
  a {
    color: $blue;
  }
}

.subinfo {
  margin-top: 20px;
  padding: 10px;

  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #d5e1fd;
  border-radius: 6px;
  .subinfoText {
    color: $gray;
    span {
      font-weight: 700;
    }
  }
}
.tableWrapper {
  overflow: auto;
}
.table {
  padding-bottom: 10px;
  @include b(600) {
    min-width: 600px;
  }
}
.tableHeader {
  margin-bottom: 10px;
  padding: 0 10px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  &FirstColumn {
    margin-left: -10px;
  }
  &Item {
    cursor: pointer;
  }
}
.tableRow {
  padding: 15px 10px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: rgba($blue, 0.1);
  border-radius: 6px;
  & + & {
    margin-top: 5px;
  }
  p {
    color: #32335a;
  }
}
