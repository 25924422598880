@import '/src/styles/variables';
@import '/src/styles/mixins';

.dashboard_wrapper {
  @include container(770);
  padding: 0;
}

.section {
  padding: 30px 40px;
  margin: 5px 0 20px;
  border-radius: $borderRadius;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;

  &.blue {
    background: rgba($blue, 0.1);
  }
  &.dark {
    background: rgba($darkBlue, 0.1);
  }
  &.pink {
    background: rgba($purple, 0.1);
  }
  &.green {
    background: rgba($green, 0.1);
  }

  &.referrals {
    grid-template-columns: 0.7fr 1fr 1fr;
    @include b(700) {
      grid-template-columns: 1fr 1fr;
    }
    @include b(500) {
      grid-template-columns: 1fr;
    }
  }

  @include b(500) {
    grid-template-columns: 1fr;
    padding: 20px;
  }
}

.label {
  margin-bottom: 5px;
}

.numbers {
  color: $petrol;
}
