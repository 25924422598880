@import '/src/styles/variables';
@import '/src/styles/mixins';

.rewards {
}
.expander {
  margin: 20px 0;
}
.section {
  background: rgba($green, 0.1);
  border-radius: $borderRadius;
  padding: 20px 30px;
  margin-top: 20px;

  @include b(500) {
    padding: 15px 25px;
  }
}

.title {
  @include flex;
  align-items: center;
  margin-bottom: 30px;
}

.values {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;

  @include b(500) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

.buttonLabel {
  margin-bottom: 15px;
}

.label {
  margin-bottom: 5px;
}

.oneLogo {
  width: 30px;
  height: 30px;
  z-index: 2;
  margin-right: 10px;
}

.ftmLogo {
  width: 30px;
  height: 30px;
  margin-left: -20px;
  margin-right: 10px;
}

.timeLeft {
  font-size: 14px;
}

.penaltyTitle {
  margin-bottom: 10px;
}

.subsection {
  padding: 10px;
  color: $textLabel2;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 10px;
  }
}

.buttonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-bottom: 8px;

  @include b(500) {
    grid-template-columns: 1fr;
  }
}

.claim_table_header {
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  row-gap: 20px;
  padding-top: 12px;
  margin-bottom: 8px;
}
.claim_table_content {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr);
  row-gap: 20px;

  * {
    @include flex(row, flex-start, center);
  }
}
.claim_table_content__grayOut {
  opacity: 0.25;
}
.logo_title_wrapper {
  :first-child {
    margin-right: 8px;
  }
}
