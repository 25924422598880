@import '/src/styles/variables';
@import '/src/styles/mixins';

.page_wrapper {
  @include container(800);
}

.content {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 50px;
  align-items: center;
}

@include b(860) {
  .content {
    grid-template-columns: 1fr;
    max-width: 500px;
  }
  .page_wrapper {
    max-width: 500px;
    @include flex(column);
  }
}

.left {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 25px;
  align-items: center;
}

.title {
  font-size: 24px;
  margin-bottom: 5px;
}

.right {
  @include flex(column, center, center);
}

.balanceTitle {
  margin-bottom: 8px;
}

.balance {
  @include flex(row, center);
  align-items: center;
  margin-bottom: 20px;
}

.subtitle{
  margin-top: 10px;
  text-align: center;
  font-size: 13px;
  opacity: 0.8;
}

.xbfLogo {
  margin-right: 10px;
}

.balanceAmount {
  font-size: 32px;
}

.balanceAmountUsd {
  margin-bottom: 20px;
}
